import axios from "@/plugins/axios";
import router from "@/router";
import { assertIsDefined } from "@/utils/asserts";
import moment from "moment";
import { reactive } from "vue";
import { NavigationFailure } from "vue-router";

/**
 * 何もしない関数
 */
export const noop = (): void => {
  console;
};
export const TypeHints = Object.freeze({
  /** 文字列型 */
  STRING: 0,
  /** 日付型 */
  DATETIME: 1,
  /** 整数型 */
  INTEGER: 2,
  /** 浮動小数点型 */
  DOUBLE: 3,
  /** 真偽値 */
  BOOLEAN: 4,
  /** 日付の開始終了日 */
  DATETIME_RANGE: 7,
  /** リスト型 */
  LIST: 9,
});
/**
 * コンボボックス用のオプション
 */
export type ComboboxOption = {
  text: string;
  value: string;
};
/**
 * 親を持つオプション
 */
export type ComboboxChildOption = {
  parent: ComboboxOption;
} & ComboboxOption;
/**
 * 子を持つオプション
 */
export type ComboboxParentOption = {
  children: ComboboxOption[];
} & ComboboxOption;
/**
 * `ComboboxChildOption[]` -> `ComboboxParentOption[]`
 * @param children
 * @returns
 */
export function childToParent(
  children: ComboboxChildOption[]
): ComboboxParentOption[] {
  const parents: ComboboxParentOption[] = [];
  let prevParent: ComboboxOption | null = { text: "", value: "" };
  const buffer: ComboboxOption[] = [];
  const pushParent = () => {
    const children = buffer.splice(0, buffer.length);
    const parent = Object.assign({}, prevParent, { children });
    parents.push(parent);
  };
  for (const { value, text, parent } of children) {
    if (prevParent?.value != parent.value) {
      if (buffer.length > 0) {
        pushParent();
      }
      prevParent = parent;
    }
    buffer.push({ value, text });
  }
  if (buffer.length > 0) {
    pushParent();
  }
  return parents;
}
/**
 * `ComboboxParentOption[]` -> `ComboboxChildOption[]`
 * @param parents
 * @returns
 */
export function parentToChild(
  parents: ComboboxParentOption[]
): ComboboxChildOption[] {
  const results: ComboboxChildOption[] = [];
  for (const { text, value, children } of parents) {
    const parent: ComboboxOption = { text, value };
    for (const { text, value } of children) {
      results.push({
        text,
        value,
        parent,
      });
    }
  }
  return results;
}
export type UploadableModel = {
  uploadAt: string | null;
  updateAt: string;
};
export type UploadableWithPrivateModel = {
  isPrivate: boolean;
} & UploadableModel;
/**
 * クラウド同期がされていない
 * @param model
 * @returns されてないなら真を返す
 */
export function isNotUpload(
  model: UploadableModel | UploadableWithPrivateModel
): boolean {
  const uploadAt: string | null = model.uploadAt;
  const updateAt: string = model.updateAt;
  const isPrivate: boolean | undefined = (model as UploadableWithPrivateModel)
    ?.isPrivate;
  let result: boolean | undefined = undefined;
  let checkType: "private" | "noPrivate" | "moment" | undefined = undefined;
  try {
    if (isPrivate === true) {
      checkType = "private";
      return (result = uploadAt !== null);
    }
    if (uploadAt === null) {
      checkType = "noPrivate";
      return (result = true);
    }
    checkType = "moment";
    return (result = moment(uploadAt).isBefore(updateAt));
  } finally {
    console.debug(
      "isNotUpload: %o -> %o (checkType:%o)",
      { uploadAt, updateAt, isPrivate },
      result,
      checkType
    );
  }
}
export type DownloadItemParam = {
  /** ファイルURL */
  url: string;
  /** ファイル名 */
  label?: string;
  /** ダウンロードするコンテントタイプ */
  type?: string;
};
const DISPOSITION_FILENAME_UTF8_PATTERN =
  /filename\*=UTF-8''(?<filename>[^;]+)(?:$|;)/u;
const DISPOSITION_FILENAME_PATTERN = /filename=(?<filename>[^;]+)(?:$|;)/u;
/**
 * URLをダウンロードする
 * @param param ダウンロードURL もしくはオブジェクト
 */
export async function downloadItem(
  param: DownloadItemParam | string
): Promise<void> {
  if (typeof param === "string") {
    param = { url: param };
  }
  const { url } = param;
  let type = param.type ?? null;
  let label = param.label ?? null;
  const response = await axios.get(url, { responseType: "blob" });
  if (type == null) {
    type = response.headers["content-type"];
  }
  if (label == null) {
    const disposition = response.headers["content-disposition"];
    console.debug("content-disposition: %o", disposition);
    if (label == null) {
      const filename = disposition.match(DISPOSITION_FILENAME_UTF8_PATTERN)
        ?.groups?.filename;
      if (filename != null) {
        label = decodeURIComponent(filename);
      }
    }
    if (label == null) {
      const filename = disposition.match(DISPOSITION_FILENAME_PATTERN)?.groups
        ?.filename;
      if (filename != null) label = filename;
    }
  }
  const blob = new Blob([response.data], { type });
  const link = document.createElement("a");
  if (label != null) {
    link.download = label;
    console.debug("use filename: %o", label);
  }
  link.href = URL.createObjectURL(blob);
  try {
    link.click();
  } finally {
    URL.revokeObjectURL(link.href);
  }
}

export type PageParams = {
  page: string;
  pageSize: string;
};
export type PageRequest = {
  page: number;
  pageSize: number | null;
};
export type PageResult = {
  /**
   * ページ INDEX
   */
  index: number;
  /**
   * 1ページあたりのレコード数
   */
  size: number;
  /**
   * レコード数
   */
  total: number;
  /**
   * ページ数
   */
  count: number;
  /**
   * 現在の index のページに、次のページがあるか
   */
  hasNext: boolean;
};

export type SearchConfig = {
  signal?: AbortSignal;
};

/**
 * 検索サービス実装
 */
export abstract class SearchService<
  TParam extends Record<string, string>,
  TResult
> {
  constructor(
    options:
      | {
          /**
           * 初期検索結果一覧リスト
           */
          list?: TResult[];
          /**
           * 初期ページ情報
           */
          page?: PageResult;
          /**
           * 初期パラメータ
           */
          params?: TParam;
          /**
           * 除外対象キー配列
           */
          omit?: string[];
        }
      | undefined = undefined
  ) {
    const list = options?.list;
    const page = options?.page;
    const params = options?.params;
    this._list = reactive<TResult[]>(list ?? []) as TResult[];
    this._params = params ?? null;
    this._page = reactive(
      page ?? {
        count: 0,
        hasNext: false,
        index: 0,
        size: 0,
        total: 0,
      }
    ) as PageResult;
    this._omit = options?.omit ?? [];
  }
  private _params: TParam | null = null;
  private _list: TResult[];
  private _omit: string[];
  get list(): TResult[] {
    return this._list;
  }
  private _promise: Promise<void> = Promise.resolve();
  /**
   * 待ち状態
   */
  get promise(): Promise<void> {
    return this._promise;
  }
  /**
   * 非同期待ちの追加
   * @param value
   * @returns
   */
  protected putPromise(value: Promise<void>): Promise<void> {
    return (this._promise = this._promise.then(() => value.catch(noop)));
  }
  private _page: PageResult;
  /**
   * 現在のページ位置
   */
  get page(): PageResult {
    return this._page;
  }
  /**
   * 検索条件を元に 検索内容に相違があるかチェックする
   * @param params
   * @param config
   * @returns
   */
  searchOrRefresh(
    params: TParam,
    config?: { signal?: AbortSignal }
  ): Promise<void> {
    const isRefresh = this.isRefresh(params);
    console.debug("load: search or refresh: %o", { isRefresh });
    if (isRefresh) return this.refresh(config);
    return this.search(params, config);
  }
  /**
   * `this._omit` に設定されている除外設定キーを元に `params` からそのキーを除外する
   * @param params
   * @returns
   */
  private omit(params: TParam): TParam {
    params = Object.assign({}, params);
    if (this._omit.length > 0) {
      for (const key of this._omit) {
        delete params[key];
      }
    }
    return params;
  }
  /**
   * `params` が 現在の `this._params` と違いがあるのかをチェックする
   * @param params
   * @returns
   */
  private isRefresh(params: TParam): boolean {
    params = this.omit(params);
    if (this._params == null) return false;
    for (const key in this._params) {
      const newParam = params[key];
      const oldParam = this._params[key];
      if (newParam !== oldParam) return false;
    }
    return true;
  }
  private searchController: AbortController = new AbortController();
  private wrapedConfig(config?: SearchConfig): {
    config: SearchConfig;
    dispose?: () => void;
  } {
    // abortcontroller を更新する
    this.searchController.abort();
    const searchController = (this.searchController = new AbortController());
    const signal = this.searchController.signal;
    if (!config?.signal) {
      return {
        config: Object.assign(config ?? {}, { signal }),
        dispose: noop,
      };
    }
    config.signal.addEventListener("abort", abort);
    const dispose = () => config.signal?.removeEventListener("abort", abort);
    return {
      config: Object.assign(config ?? {}, { signal }),
      dispose,
    };
    function abort() {
      searchController.abort();
    }
  }
  /**
   * 検索を開始する
   * @param params
   */
  search(params: TParam, config?: SearchConfig): Promise<void> {
    console.debug("load search: %o", { params: Object.assign({}, params) });
    params = this.omit(params);
    this._params = params;

    const { config: _config, dispose } = this.wrapedConfig(config);
    config = _config;
    const promise = this.load(
      {
        ...params,
        page: "0",
        pageSize: "",
      },
      true,
      config
    );
    this.putPromise(promise);
    promise.finally(dispose);
    return promise;
  }
  refresh(config?: SearchConfig): Promise<void> {
    console.debug("load refresh: %o", {
      params: Object.assign({}, this._params ?? {}),
    });
    assertIsDefined(this._params);
    const params = this._params;
    const { config: _config, dispose } = this.wrapedConfig(config);
    config = _config;
    const promise = this.reload(
      {
        ...params,
        page: `${this.page.index ?? 0}`,
        pageSize: "",
      },
      config
    );
    this.putPromise(promise);
    promise.finally(dispose);
    return promise;
  }
  /**
   * 次のリストを読み込む
   * @returns
   */
  next(config?: SearchConfig): Promise<void> {
    console.debug("load next: %o", {
      params: Object.assign({}, this._params ?? {}),
    });
    if (!this._page.hasNext) throw new Error("not have next");
    assertIsDefined(this._params);
    const { config: _config, dispose } = this.wrapedConfig(config);
    config = _config;
    const promise = this.load(
      {
        ...this._params,
        // 次のページINDEXを選択する
        page: `${this._page.index + 1}`,
        pageSize: `${this._page.size}`,
      },
      false,
      config
    );
    this.putPromise(promise);
    promise.finally(dispose);
    return promise;
  }
  protected abstract get getPath(): string;
  /**
   * リストの読み込みを行う
   * @param params パラメータ
   * @param isNew 新規読み込みを行う
   */
  protected async load(
    params: TParam & PageParams,
    isNew: boolean,
    config?: SearchConfig
  ): Promise<void> {
    try {
      const signal = config?.signal;
      const allList: TResult[] = [];
      let lastPage: PageResult | undefined = undefined;
      let isError = false;
      if (isNew) this._list.splice(0);
      try {
        const { list, page } = await this.loadSingle(params, config);
        allList.push(...list);
        lastPage = page;
      } catch (e) {
        isError = true;
        throw e;
      } finally {
        const aborted = (signal?.aborted ?? false) || isError;
        if (!aborted) {
          this._list.splice(this._list.length, 0, ...allList);
          Object.assign(this._page, lastPage);
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  protected async reload(
    params: TParam & PageParams,
    config?: SearchConfig
  ): Promise<void> {
    try {
      const signal = config?.signal;
      const allList: TResult[] = [];
      let lastPage: PageResult | undefined = undefined;
      let isError = false;
      try {
        const { list, page } = await this.loadFromFirst(params, config);
        allList.push(...list);
        lastPage = page;
      } catch (e) {
        isError = true;
        throw e;
      } finally {
        const aborted = (signal?.aborted ?? false) || isError;
        if (!aborted) {
          this._list.splice(0, this._list.length, ...allList);
          Object.assign(this._page, lastPage);
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  /**
   * リストの単体読込を行う
   * @param params パラメータ
   * @param config オプション
   */
  protected async loadSingle(
    params: TParam & PageParams,
    config?: SearchConfig
  ): Promise<{ list: TResult[]; page: PageResult }> {
    console.debug("load single: page:%o", params.page);
    const query = (() => {
      const sp = new URLSearchParams();
      for (const [key, value] of Object.entries(params)) {
        if (value === null && value === "") continue;
        sp.append(key, value);
      }
      return sp;
    })().toString();
    const response = await axios.get<{ list: TResult[]; page: PageResult }>(
      `${this.getPath}?${query}`,
      config
    );
    return response.data;
  }
  /**
   *
   * @param params パラメータ
   * @param config オプション
   */
  protected async loadFromFirst(
    params: TParam & PageParams,
    config?: SearchConfig
  ): Promise<{ list: TResult[]; page: PageResult }> {
    const imax = parseInt(params.page ?? "0");
    const allList: TResult[] = [];
    let lastPage: PageResult;
    let i = 0;
    do {
      console.debug("load from first: %o / %o", i, imax);
      const p = Object.assign({}, params, { page: `${i}` });
      const { list, page } = await this.loadSingle(p, config);
      allList.push(...list);
      lastPage = page;
      if (!page.hasNext) {
        console.debug("load from first: not have next");
        break;
      }
    } while (i++ < imax);
    console.debug("load from first: finish.");
    return { list: allList, page: lastPage };
  }
}
/**
 * `search` に option を マージしたものでクエリ文字列を作成する
 * @param search
 * @param addParam
 * @returns
 */
export function createQuery<TSearch extends Record<string, string>>(
  search: TSearch,
  addParam: Partial<TSearch> | undefined = undefined
): string {
  const record = Object.assign({}, search, addParam ?? {});
  const params = new URLSearchParams();
  for (const [name, value] of Object.entries(record)) {
    if (value === "") continue;
    params.append(name, value);
  }
  const query = params.toString();
  console.debug("create query: ", { record, params, query });
  return query;
}

/**
 * 検索クエリを足したり引いたりした検索クエリ用ユーティリティクラス
 */
export class RouteQuery<T extends Record<string, string>> {
  private pathBase: string;
  private init: T;
  constructor(pathBase: string, init: T) {
    this.pathBase = pathBase;
    this.init = init;
  }
  /**
   * 指定した パラメータでURLを push する
   * @param addParam 追加するパラメータ
   * @returns
   */
  push(addParam?: Partial<T>): Promise<void | NavigationFailure | undefined> {
    const path = `${this.pathBase}?${createQuery(this.init, addParam)}`;
    const result = router.push(path);
    console.debug("push: %o", path);
    return result;
  }
  /**
   * 指定した パラメータでURLを replace する
   * @param addParam 追加するパラメータ
   * @returns
   */
  replace(
    addParam?: Partial<T>
  ): Promise<void | NavigationFailure | undefined> {
    const path = `${this.pathBase}?${createQuery(this.init, addParam)}`;
    const result = router.replace(path);
    console.debug("push: %o", path);
    return result;
  }
}
export class RouteOrder<T extends { orderBy: string }> {
  private search: T;
  private query: RouteQuery<T>;
  private defaultOrder: string;
  constructor(search: T, query: RouteQuery<T>, defaultOrder: string | number) {
    this.search = search;
    this.query = query;
    this.defaultOrder = `${defaultOrder}`;
  }
  /**
   * 並び順の選択状態判定
   * @param index
   * @returns
   */
  isSelected(index: string | number): boolean {
    return (
      (this.search.orderBy ? this.search.orderBy : this.defaultOrder) ===
      `${index}`
    );
  }
  /**
   * 並び順を変更する
   * @param newOrder
   * @returns
   */
  change(
    newOrder: string | number
  ): Promise<void | NavigationFailure | undefined> {
    const orderBy = `${newOrder}`;
    const addParam = { orderBy } as Partial<T>;
    return this.query.replace(addParam);
  }
}
